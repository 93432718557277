import(/* webpackMode: "eager" */ "/opt/sbh_schablone/frontend/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/opt/sbh_schablone/frontend/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/opt/sbh_schablone/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/opt/sbh_schablone/frontend/src/components/Popover/Popover.tsx");
import(/* webpackMode: "eager" */ "/opt/sbh_schablone/frontend/src/icons/file.svg");
import(/* webpackMode: "eager" */ "/opt/sbh_schablone/frontend/src/icons/tooltip.svg");
import(/* webpackMode: "eager" */ "/opt/sbh_schablone/frontend/src/streamfields/Accordions/Accordions.tsx");
import(/* webpackMode: "eager" */ "/opt/sbh_schablone/frontend/src/streamfields/EventFinder/EventFinder.tsx");
import(/* webpackMode: "eager" */ "/opt/sbh_schablone/frontend/src/streamfields/JobQuestionaire/JobQuestionaire.tsx");
import(/* webpackMode: "eager" */ "/opt/sbh_schablone/frontend/src/streamfields/TargetGroup/TargetGroup.tsx");
import(/* webpackMode: "eager" */ "/opt/sbh_schablone/frontend/src/views/FormPage/FormPage.tsx")